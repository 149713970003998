import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Img from 'gatsby-image'
import Wrapper from '../components/wrapper'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BlogPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
    innerImage {
      asset {
        fixed(height: 100, width: 1600) {
          ...GatsbySanityImageFixed_withWebp
        }
        metadata {
          palette {
            darkMuted {
              background
              foreground
              title
            }
            lightMuted {
              background
              foreground
              title
            }
          }
        }
      }
    }
  }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            _id
            title
            slug {
              current
             }
            } 
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const BlogPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio and add some content to "Site settings" and restart the development server.'
    )
  }
 return (
    <Layout>
      <SEO
        title='Blog'
        description='Read about topics on education, poetry, and coding.'
        keywords={site.keywords}
      />
      <Box>
      <Box
      style={{color:`${site.innerImage.asset.metadata.palette.lightMuted.title}`, textShadow: '1px 2px 6px #000000'}}
      textAlign='center'
      width='100%'
      position="absolute"
      m='2rem 0'
      zIndex="mobileStepper"
    >
    <Typography variant='h4'>Blog</Typography>
    </Box>
      <Img
      style={{
      }}
        fixed={site.innerImage.asset.fixed}
        backgroundColor={`#102514`}
        object-position={`top top`}
      />
      </Box>
      <Wrapper>
        {postNodes && (
          <BlogPostPreviewList
            title='Latest blog posts'
            nodes={postNodes}
            browseMoreHref='/archive/'
          />
        )}
      </Wrapper>
    </Layout>
  )
}

export default BlogPage
