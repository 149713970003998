import {Link as GatsbyLink} from 'gatsby'
import React, { Fragment } from 'react'
import Link from '@material-ui/core/Link'
import BlogPostPreview from './blog-post-preview'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import Box from '@material-ui/core/Box'

function BlogPostPreviewGrid (props) {
  return (
    <div>
   
<Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' width='100%'>
       <Container maxWidth='sm'>
        <Box alignSelf='flex-start'>
      
    {props.title && <Typography variant='h6'>{props.title}</Typography>}
      </Box>
      </Container>
       {props.nodes &&
          props.nodes.map(node => (
            <Fragment>
              <BlogPostPreview {...node} isInList />
           </Fragment>
          ))}
           </Box>
      {props.browseMoreHref && (
        <Container maxWidth='sm'>
        <Box display='flex' justifyContent='center'>
      
        <Link component={GatsbyLink} to={props.browseMoreHref}>Browse more</Link>
      </Box>
      </Container>
      )}
    </div>
  )
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostPreviewGrid
